

function Card(props) {
  return (
    <div class="card" style={{width: '100%', marginRight:'38px'}}>
  <div class="card-body">
    <h4 class="card-title p-2">{props.title}</h4>
    <p class="card-text">{props.ServiceResume}</p>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">{props.item1}</li>
    <li class="list-group-item">{props.item2}</li>
  </ul>
  <div class="card-body">
    <a href="#" type="button" class="mx-1 btn btn-primary">Avançar</a>
    <a href="#Contato" class="mx-1 btn btn-success">Entrar em contato</a>
  </div>
</div>
  );
}

export default Card;