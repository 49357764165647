import React from 'react';
import './Components.css';
function InfosRPA() {
    return (
            <div className="orcamento-container">
              
              <div className="section">
                <ul>
                <h4>Precisa automatizar qualquer serviço?</h4>
                  <li>1. Descreva em detalhes o que precisa ser automatizado (quais passos manuais gostaria de automatizar).</li>
                  <li>2. Mencione site e quais passos/processos gostaria de automatizar.</li>
                </ul>
              </div>
        
              <div className="section">
                <ul>
                <h4>Precisa de um sistema robusto e proprio?</h4>
                  <li>1. Descreva a visão ou quais funcionalidades imagina serem necessárias;</li>
                  <li>2. Quero um sistema para fazer X, Y, Z;</li>
                  <li>Esse sistema deve me permitir fazer A, B, C, D.</li>
                </ul>
              </div>
            </div>
    );
  }
  
  export default InfosRPA;