import { useRef } from "react";
import './Nav.css';
// const dropDownNav = useRef({});
function dropDown(e){
  // debugger
  document.querySelector(".")
  const toggle = false;

}
function NavUl() {
  return (
    <ul class="navbar-nav d-flex mr-auto">
    <li class="nav-item active">
      <a class="nav-link" href="#">Inicio</a>
    </li>
    {/* <li class="nav-item">
      <a class="nav-link" href="#">Sobre</a>
    </li> */}
    <li class="nav-item">
      <a class="nav-link" href="#">Serviços</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#Contato">Contatos</a>
    </li>
    {/* <li class="nav-item dropdown">
                
        <button class="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          Dropdown
        </button>
        <ul class="dropdown-menu dropdown-menu-dark">
          <li><a class="dropdown-item" href="#">Action</a></li>
          <li><a class="dropdown-item" href="#">Another action</a></li>
          <li><a class="dropdown-item" href="#">Something else here</a></li>
        </ul>


    </li> */}
    <li class="nav-item">
      <a class="nav-link disabled" href="#">Plataformas</a>
    </li>
    
  </ul>
     
  );
}

export default NavUl;
